<template>
    <div class="container-fluid mt-2">
        <template v-if="!currentIncidentId">
            <past-incident></past-incident>
        </template>
        <template v-if="currentIncidentId">
            <conversation :incidentId="currentIncidentId"></conversation>
        </template>
        <prompt-user :key="componentKey" :openModal="openUserModal" @closeMe="closeNewUser"></prompt-user>
    </div>
</template>

<script>

import pastIncident from "./pastIncident"
import conversation from "./conversation"
import promptUser from '../common/promptUser'

export default {
    data() {
        return {
            currentDateTime: new Date(),
            newMessage: "",
            conversationList: [],
            currentIncidentId: null,
            openUserModal: false,
            componentKey: 1
        }
    },
    components: {
        pastIncident,
        conversation,
        promptUser
    },
    computed: {
        locationId() {
            return this.$route.params.locationId;
        },
        incidentId() {
            return this.$route.params.incidentId;
        },
    },
    watch: {
        $route(to, from) {
            if (to.path !== from.path) {
                this.currentIncidentId = this.incidentId;
            }
        }
    },
    async created() {
        this.loadUser();
    },
    methods: {
        loadUser() {
            console.log("🚀 ~ loadUser ~ this.$store.getters.loggedInUser:", this.$store.getters.loggedInUser)
            if (this.$store.getters.loggedInUser === null) {
                this.openUserModal = true;
            }
        },
        closeNewUser() {
            this.componentKey ++;
            this.openUserModal = false
            this.loadUser();
        }
    }
}
</script>

<style>
#chat-form {
    position: fixed;
    left: 0;
    bottom: 60px;
    width: 100%;
    background-color: #dddddd;
    color: white;
    text-align: center;
    padding: 10px;
}
</style>