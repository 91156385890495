<template>
    <b-modal v-model="openUserModal" hide-footer no-close-on-backdrop @hidden="onHiding">
        <template #modal-title>
            Your Details
        </template>
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <h4>Please provide your info to view procedures & request help.</h4>
            <b-form-group label="Name" label-for="name-input">
                <b-form-input id="name-input" v-model="currentUser.name"></b-form-input>
            </b-form-group>

            <b-form-group label="Mobile Number" label-for="mobile-input">
                <b-form-input class="mb-2" id="mobile-input" v-model="currentUser.mobile"></b-form-input>
                <label>We will notify you in-case of an emergency.</label>
            </b-form-group>

            <button type="button" class="btn btn-primary pull-right" @click="handleOk">Submit</button>
        </form>
    </b-modal>
</template>

<script>
export default {
    components: {},
    props: {
        openModal: {
            type: Boolean,
            required: false,
            default: () => false,
        }
    },
    watch: {
        openModal: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                console.log("🚀 ~ handler ~ oldValue:", oldValue)
                console.log("🚀 ~ handler ~ newValue:", newValue)
                this.openUserModal = newValue;
            }
        }
    },
    data() {
        return {
            openUserModal: false,
            currentUser: {},
        }
    },
    async created() {

    },
    methods: {
        resetModal() {
            this.currentUser = {};
        },
        onHiding() {
            console.log("hiding");
            this.$emit("closeMe");
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            if (!this.currentUser.name || !this.currentUser.mobile) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please enter your name and mobile number",
                    color: "danger",
                    position: "top-center"
                });
                bvModalEvent.preventDefault()
                return false;
            }
            this.handleSubmit()
        },
        handleSubmit() {
            this.$store.dispatch("setLoggedInUser", this.currentUser);
            this.$vs.notify({
                title: "",
                text: "Record updated",
                color: "success",
                position: "top-center"
            });
            this.$emit("closeMe");
        }
    }
}
</script>